<template>
  <div>
    <HeadVue @loaded="headLoaded"></HeadVue>
    <router-view v-if="isHeadLoaded"></router-view>
    <FooterVue :address='address' :siteID='siteID' :siteName='siteName' v-if="isHeadLoaded"></FooterVue>
  </div>
</template>
<script>
import HeadVue from '@/components/Head.vue';
import FooterVue from '@/components/Footer.vue';

export default {
  data() {
    return {
      title: '蜻蜓集运 - 跨国集运专家 | 海外华人留学生集运服务',
      keyWords: '蜻蜓集运, 包裹集运, 国货集运, 海外华人集运, 留学生集运服务, 淘宝双十一集运, 淘宝转运, 淘宝转寄海外, 淘宝集中转运, 集运服务, 中国到海外集运',
      description: '蜻蜓集运，您的跨国集运专家。专为海外华人和留学生提供便捷、高效的集运服务。无论您身在何处，享受家乡商品的乐趣。轻松、快捷、安全，让您的海外生活更加丰富多彩。加入我们，探索更多集运优惠和服务。',
      address: '',
      siteID: '',
      siteName: '',
      isHeadLoaded: false,
    }
  },

  // metaInfo() {
  //   return {
  //     title: this.title, // set a title
  //     meta: [
  //       {
  //         // set meta
  //         name: "keyWords",
  //         content: this.keyWords,
  //       },
  //       {
  //         name: "description",
  //         content: this.description,
  //       },
  //     ],
  //   };
  // },

  metaInfo: {
    title: '蜻蜓集运 - 跨国集运专家 | 海外华人留学生集运服务',
    meta: [{
      name: 'Keywords',
      content: '蜻蜓集运, 包裹集运, 国货集运, 海外华人集运, 留学生集运服务, 淘宝双十一集运, 淘宝转运, 淘宝转寄海外, 淘宝集中转运, 集运服务, 中国到海外集运'
    },
    {
      name: 'Description',
      content: '蜻蜓集运，您的跨国集运专家。专为海外华人和留学生提供便捷、高效的集运服务。无论您身在何处，享受家乡商品的乐趣。轻松、快捷、安全，让您的海外生活更加丰富多彩。加入我们，探索更多集运优惠和服务。'
    }]
  },

  components: {
    HeadVue,
    FooterVue,
  },

  mounted() {
    let that = this;
    //获取站点信息
    that.$axios.get('site/get_site_newList?cid=33&page=1&limit=5').then(res => {
      that.title = res.data.data.list[0].title || '';//站点名称
      that.keyWords = res.data.data.list[1].title || '';//站点关键词
      // that.address = res.data.data.list[3].title || '';//公司地址
      that.siteID = res.data.data.list[2].title || '';//站点备案号
      that.siteName = res.data.data.list[1].title || '';//集运名称
    })
  },

  methods: {
    headLoaded() {
      this.isHeadLoaded = true;
    }
  },
};
</script>


<style>
@font-face {
  font-family: 'fontSimHei';
  src: url('./assets/font/simheittf.ttf') format('truetype');
  /* 可选：添加其他字体样式 */
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'fontSimHei'
}

:root {
  --theme-color:#F16A26;/* 主题色 */
  --theme-color-rgba: 241, 106, 38;/* 主题色rgb */
  --theme-font-color-bg: #E5CD52; /*字体背景颜色*/
  --theme-color-bg:#F16A26;/* 主题背景色 */
  --theme-color-title:#F16A26; /*主题标题字体 */
}


.theme-color-border {
  border: 1px solid var(--theme-color-bg) !important;
}

.theme-color {
  color: var(--theme-color) !important
}

.theme-color-bg {
  background-color: var(--theme-color-bg) !important;
}

.them-color-font-bg {
  background-color: var(--theme-font-color-bg) !important;
}

.theme_tips {
  color: var(--theme_tips) !important
}

.theme_tips_bg {
  background-color: var(--theme_tips_bg) !important
}

p {
  margin: 0
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
